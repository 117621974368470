import React from 'react';
import Carousel from 'react-hook-carousel'

function App() {
  const slider = [
    {
      image: "https://vaikuntum-usa.s3.amazonaws.com/Krsna_In_The_Vrindavan_Forest.jpg",
      alt: "alt image1"
    },
    {
      image: "https://vaikuntum-usa.s3.amazonaws.com/krsnainvaikuntha.jpeg",
      alt: "alt image1"
    },
    {
      image: "https://vaikuntum-usa.s3.amazonaws.com/large.jpeg",
      alt: "alt image2"
    }
  ];

  return (
    <div>
      <Carousel items={slider} groupBy={false} showDots={false} effect="fade" />
    </div>
  );
}

export default App;
